import Layout1 from '@/layout/Layout1'

export default [{
  path: '/',
  component: Layout1,
  children: [
    {
      path: 'addresses',
      component: () => import('@components/user/Addresses')
    },
    {
      path: 'address',
      component: () => import('@components/user/Address')
    },
    {
      path: 'address/:id',
      component: () => import('@components/user/Address')
    },
    {
      path: 'alerts',
      component: () => import('@components/user/Alerts')
    },
    {
      path: 'alert/:id',
      component: () => import('@components/user/Alert')
    },
    {
      path: 'profile',
      component: () => import('@components/user/Profile')
    }
  ]
}]
