/* global FB */
/* global FCM */

// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App'
import router from './router'

import BootstrapVue from 'bootstrap-vue'

import globals from './globals'
import Popper from 'popper.js'

import axios from 'axios'
import ENV from '../../env.json'

if (process.env.CORDOVA_PLATFORM) {
  try {
    const fcmCheck = setInterval(() => {
    // setTimeout(() => {
      console.log(FCM, typeof FCM)
      // console.log(FCMPlugin)
      if (typeof FCM !== 'undefined') {
        Vue.prototype.$FCM = FCM
        clearInterval(fcmCheck)
        console.log('FCM subscribe')

        FCM.subscribeToTopic('alerts')

        FCM.onNotification(function (data) {
          if (data.wasTapped) {
            // Notification was received on device tray and tapped by the user.
            console.log(data)
            // alert( JSON.stringify(data) );
            // alert( data.title+'\n'+data.body );
            if (data.id) {
              router.push('/alert/' + data.id)
            }
            // if(data.title) alert( data.title+'\n'+data.body );
          } else {
            // Notification was received in foreground. Maybe the user needs to be notified.
            console.log(data)
            // alert( JSON.stringify(data) );
            // alert( data.title );
            if (data.title) alert(data.title + '\n' + data.message)
            // if (data.id) {
            //   router.push('/alert/' + data.id)
            // }
          }
        }, function (msg) {
          // alert(JSON.stringify(msg))
          console.log(msg)
        })
      }
    },
    300)
  } catch (e) {
    console.log(e)
  }
} else {
  // load facebook sdk script
  (function (d, s, id) {
    var js
    var fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) { return }
    js = d.createElement(s)
    js.id = id
    js.src = 'https://connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  }(document, 'script', 'facebook-jssdk'))

  window.fbAsyncInit = function () {
    FB.init({
      appId: ENV.FACEBOOK_APP_ID,
      cookie: true,
      xfbml: true,
      version: 'v12.0'
    })
  }
}

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.prototype.$http = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? ENV.apiUrlLocal + '/cetatean' : ENV.apiUrl + '/cetatean',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
Vue.prototype.$httpPublic = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? ENV.apiUrlLocal + '/cetatean' : ENV.apiUrl + '/cetatean',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

Vue.prototype.$http.defaults.timeout = 10000

Vue.prototype.$http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access_token')
    if (token) {
      config.headers.common.Authorization = 'Bearer ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

Vue.prototype.$http.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          //  do something
          break
        case 401:
          localStorage.removeItem('access_token')
          // alert('session expired')
          router.replace({
            path: '/auth/login',
            query: { redirect: router.currentRoute.fullPath }
          })
          break
        case 403:
          localStorage.removeItem('access_token')
          router.replace({
            path: '/auth/login',
            query: { redirect: router.currentRoute.fullPath }
          })
          break
        case 404:
          alert('page not exist')
          break
        case 500:
        case 502:
          localStorage.removeItem('access_token')
          setTimeout(() => {
            router.replace({
              path: '/auth/login',
              query: {
                redirect: router.currentRoute.fullPath
              }
            })
          }, 1000)
      }
      return Promise.reject(error.response)
    }
  }
)

Vue.filter('str_limit', function (value, size) {
  if (!value) return ''
  value = value.toString()

  if (value.length <= size) {
    return value
  }
  return value.substr(0, size) + '...'
})

Vue.use(BootstrapVue)

// Global RTL flag
Vue.mixin({
  data: globals
})

new Vue({
  router,
  render: h => h(App)
  // created () {
  //   // Prevent blank screen in Electron builds
  //   this.$router.push('/home')
  // }
}).$mount('#app')
